/*
* Theme Name: les Satellites
* Author: Com'Vous & Nous
* Text Domain: les-satellites
* Domain Path: /lang
*/
.ico-menu-burger {
  background-image: url("assets/icons/icon_menu.svg");
  width: 30px;
  height: 30px;
  display: inline-block;
}

.ico-menu-close {
  background-image: url("assets/icons/icon_close.svg");
  width: 30px;
  height: 30px;
  display: inline-block;
}

.ico-hour {
  background-image: url("assets/icons/hour.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-mail {
  background-image: url("assets/icons/email.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
}

.ico-phone {
  background-image: url("assets/icons/phone.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
}

.ico-localisation {
  background-image: url("assets/icons/localisation.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-arrow-white-down {
  background-image: url("assets/icons/arrow-white-down.svg");
  width: 19px;
  height: 12px;
  display: inline-block;
}

.ico-arrow-white-up {
  background-image: url("assets/icons/arrow-white-up.svg");
  width: 19px;
  height: 12px;
  display: inline-block;
}

.ico-car {
  background-image: url("assets/icons/car.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-bus {
  background-image: url("assets/icons/bus.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-tram {
  background-image: url("assets/icons/tram.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-train {
  background-image: url("assets/icons/train.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-velo {
  background-image: url("assets/icons/velo.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-electric {
  background-image: url("assets/icons/electric.svg");
  width: 19px;
  height: 18px;
  display: inline-block;
}

.ico-site {
  background-image: url("assets/icons/site.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.ico-email-black {
  background-image: url("assets/icons/email-black.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.ico-linkedin-circle-blue {
  background-image: url("assets/icons/linkedin-circle-blue.svg");
  width: 55px;
  height: 55px;
  display: inline-block;
}

.ico-arrow-left-brown {
  background-image: url("assets/icons/arrow-left-brown.svg");
  width: 23px;
  height: 18px;
  display: inline-block;
}

.skip-link screen-reader-text {
  display: none;
}

body {
  background-color: #f7f5f2 !important;
}

#content {
  overflow: hidden;
}

body:not(.home) #content {
  padding-top: 137px;
}
@media (max-width: 639px) {
  body:not(.home) #content {
    padding-top: 80px;
  }
}

.admin-bar .header {
  top: 32px;
}
@media (max-width: 639px) {
  .admin-bar .header {
    top: 46px;
  }
  .admin-bar .header.scrolling {
    top: 0;
  }
}

.flex-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .flex-list {
    flex-direction: column;
  }
}

.article-content {
  padding: 0 50px;
}
@media (max-width: 639px) {
  .article-content {
    padding: 0 25px;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  transform: translateX(-50%);
  transition: all 0.6s;
  opacity: 0;
}
.section-title.visible {
  transform: none;
  opacity: 1;
}
@media (max-width: 639px) {
  .section-title {
    text-align: left;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 27px;
  }
}

.is-mobile {
  display: none;
}

.is-desktop {
  display: block;
}

@media (max-width: 639px) {
  .is-mobile {
    display: block;
  }
  .is-desktop {
    display: none;
  }
}
.footer {
  position: relative;
  background-color: #faae48;
  padding-top: 260px;
  margin-top: 325px;
}
.footer.visible .footer_contact-container {
  top: -225px;
  opacity: 1;
}
.footer.no-margin-top {
  margin-top: 0;
}
@media (max-width: 899px) {
  .footer {
    padding-top: 0;
    margin-top: 80px;
  }
}
.footer_contact-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 50px;
  opacity: 0;
  transition: all 0.6s ease-in;
}
@media (max-width: 899px) {
  .footer_contact-container {
    position: static;
    top: 0;
    padding: 0;
  }
}
.footer_logo-container {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 639px) {
  .footer_logo-container {
    padding: 46px 0;
  }
  .footer_logo-container img {
    height: 41px;
  }
}
.footer_menu-container {
  background-color: #efe0cb;
  padding: 16px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 899px) {
  .footer_menu-container {
    padding: 30px 25px;
    flex-direction: column;
  }
}
.footer_menu-container_menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
@media (max-width: 899px) {
  .footer_menu-container_menu {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.footer_menu-container_menu_item {
  list-style: none;
  margin-right: 72px;
}
@media (max-width: 899px) {
  .footer_menu-container_menu_item {
    margin-bottom: 20px;
    margin-right: 0;
    width: 50%;
  }
}
.footer_menu-container_menu_item:last-child {
  margin-right: 0;
}
.footer_menu-container_menu_item a {
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  color: #000;
  letter-spacing: 0.2px;
  line-height: 27px;
}
.footer_menu-container_menu_item a:hover {
  text-decoration: none;
}
.footer_menu-container_social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
@media (max-width: 899px) {
  .footer_menu-container_social {
    margin-top: 15px;
  }
}
.footer_menu-container_social_item {
  list-style: none;
  margin-right: 50px;
}
.footer_menu-container_social_item:last-child {
  margin-right: 0;
}
.footer_bottom-line {
  background-color: #ddc4ad;
  padding: 0 50px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 639px) {
  .footer_bottom-line {
    height: 40px;
    padding: 0;
    justify-content: center;
  }
}
.footer_bottom-line p {
  font-family: "Work Sans", serif;
  font-size: 14px;
  color: #000;
  text-align: right;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin: 0;
}
@media (max-width: 639px) {
  .footer_bottom-line p {
    font-size: 12px;
    line-height: 21px;
  }
}

.header {
  background-color: rgba(255, 255, 255, 0.95);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  transition: top 0.5s ease-in;
  animation-delay: 0s, 3s;
  animation-name: appear;
  animation-duration: 0.8s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(-110px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.header.minify > .header_top-menu {
  opacity: 0;
  height: 0;
  padding: 0;
}
.header.minify > .wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
  height: 60px;
}
.header.minify > .wrapper .logo-atellites {
  opacity: 0;
}
.header.minify > .wrapper .logo-s {
  transform: scale(0.5);
}
.header_top-menu {
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  padding: 5px 50px;
  background-color: rgba(227, 90, 67, 0.6);
  transition: all 0.6s;
}
@media (max-width: 639px) {
  .header_top-menu {
    padding: 5px 20px;
  }
}
.header_top-menu ul {
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 16px;
}
.header_top-menu ul li {
  list-style: none;
}
.header_top-menu ul li a {
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 26px;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 8px 15px;
}
.header_top-menu ul li a:hover {
  font-weight: 600;
}
.header_top-menu ul li a:last-child {
  padding-right: 0;
}
.header_menu .header_menu_navigation_open-btn {
  display: none;
}
@media (max-width: 639px) {
  .header_menu .header_menu_navigation_open-btn {
    display: flex;
  }
}
@media (max-width: 639px) {
  .header_menu_navigation {
    opacity: 0;
    position: absolute;
    top: 0;
    right: -100%;
    background-color: #e35a43;
    width: 100%;
    height: 100vh;
    padding: 19px 25px;
    transition: all 0.5s ease-in;
  }
}
.header_menu_navigation.opened {
  right: 0;
  opacity: 1;
}
.header_menu_navigation_close-btn {
  display: none;
  position: absolute;
  top: 19px;
  right: 25px;
}
@media (max-width: 639px) {
  .header_menu_navigation_close-btn {
    display: block;
  }
}
.header_menu_navigation_logo-mobile {
  display: none;
}
@media (max-width: 639px) {
  .header_menu_navigation_logo-mobile {
    display: block;
  }
}
.header .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  height: 110px;
  transition: all 0.6s;
}
.header .wrapper .logo-atellites {
  margin-left: -45px;
  opacity: 1;
  transition: all 0.6s;
}
.header .wrapper .logo-s {
  transition: all 0.6s;
}
@media (max-width: 639px) {
  .header .wrapper {
    padding: 25px 20px;
    height: 80px;
  }
  .header .wrapper .hdr-logo img {
    height: 41px;
  }
  .header .wrapper .hdr-logo img.logo-atellites {
    margin-left: -35px;
    width: 150px;
  }
}
.header_top-menu + .wrapper {
  padding-top: 0;
  height: 96px;
}
@media (max-width: 639px) {
  .header_top-menu + .wrapper {
    padding-top: 20px;
    height: 80px;
  }
}
@media (max-width: 639px) {
  .header .nav-main {
    height: calc(100% - 60px);
    display: flex;
  }
}
.header .nav-main ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0;
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .header .nav-main ul {
    flex-direction: column;
    width: 100%;
    justify-content: center;
  }
}
.header .nav-main ul li {
  list-style: none;
  height: 36px;
  line-height: 36px;
}
@media (max-width: 1199px) {
  .header .nav-main ul li {
    margin-right: 50px;
  }
}
@media (min-width: 900px) {
  .header .nav-main ul li {
    margin-right: 25px;
  }
}
@media (max-width: 639px) {
  .header .nav-main ul li {
    margin-right: 0;
    height: 55px;
    display: block;
  }
}
.header .nav-main ul li.current-menu-item a {
  font-weight: bold;
}
.header .nav-main ul li:last-child {
  margin-right: 0;
}
.header .nav-main ul li:last-child a {
  border-color: #e35a43;
  color: #e35a43;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.5s ease-in;
}
.header .nav-main ul li:last-child a:hover {
  color: #fff;
  background-color: #e35a43;
}
@media (max-width: 639px) {
  .header .nav-main ul li:last-child a {
    color: #fff;
    border-color: #fff;
    padding: 17px 21px;
    font-size: 14px;
    line-height: 26px;
  }
}
.header .nav-main ul li a {
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  color: #292929;
  letter-spacing: 0.2px;
  line-height: 26px;
  font-weight: normal;
  border: 1px solid transparent;
  padding: 8px 15px;
}
.header .nav-main ul li a:hover {
  font-weight: bold;
}
@media (max-width: 639px) {
  .header .nav-main ul li a {
    color: #fff;
    font-size: 20px;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.form_container {
  background-color: #1584c3;
  padding: 40px 60px;
  color: #fff;
  width: 45%;
  max-width: 633px;
}
@media (max-width: 899px) {
  .form_container form p:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 899px) {
  .form_container {
    width: auto;
    padding: 30px 25px;
    margin: 0 -25px;
  }
}
.form_container .wpcf7-form.sent {
  display: none;
}
.form_container .screen-reader-response {
  height: 100% !important;
  position: relative !important;
  width: 100% !important;
  font-family: "Work Sans", sans-serif;
  font-size: 27px;
  color: #fff;
  line-height: 43px;
}
.form_container .screen-reader-response p {
  font-family: "Work Sans", sans-serif;
  font-size: 27px;
  color: #fff;
  line-height: 43px;
}
.form_container_title {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 26px;
  text-align: center;
}
@media (max-width: 899px) {
  .form_container_title {
    font-size: 14px;
  }
}
.form_container label {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 27px;
}
.form_container textarea, .form_container input[type=text], .form_container input[type=email] {
  border: none;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  color: #1584c3;
  letter-spacing: 0.2px;
  line-height: 27px;
}
@media (max-width: 899px) {
  .form_container textarea, .form_container input[type=text], .form_container input[type=email] {
    font-size: 12px;
  }
}
.form_container input[type=text], .form_container input[type=email] {
  height: 50px;
}
.form_container textarea {
  height: 130px;
}
@media (max-width: 899px) {
  .form_container textarea {
    height: 160px;
  }
}
.form_container input[type=submit] {
  background-color: #faae48;
  padding: 20px 60px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
}
@media (max-width: 899px) {
  .form_container input[type=submit] {
    margin-top: 20px;
    font-size: 14px;
    padding: 17px 20px;
  }
}

.block-contact {
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 1266px;
}
@media (max-width: 899px) {
  .block-contact {
    flex-direction: column;
  }
}
@media (max-width: 639px) {
  .block-contact {
    flex-direction: column;
  }
}
.block-contact_address {
  width: 50%;
  max-height: 475px;
  overflow: hidden;
  padding: 45px 65px;
  background-color: #ee8361;
}
.block-contact_address--second-bg {
  background-color: #faae48;
}
@media (max-width: 899px) {
  .block-contact_address {
    width: auto;
  }
}
@media (max-width: 639px) {
  .block-contact_address {
    padding: 40px 45px;
    width: auto;
  }
}
.block-contact_address_title {
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
@media (max-width: 639px) {
  .block-contact_address_title {
    margin-bottom: 24px;
    text-align: center;
  }
}
.block-contact_address_subtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 15.5px;
  color: #fafafa;
  line-height: 26px;
  margin-bottom: 20px;
}
@media (max-width: 639px) {
  .block-contact_address_subtitle {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 25px;
  }
}
.block-contact_address_btn-container {
  text-align: center;
}
.block-contact_address_btn {
  background-color: #fff;
  padding: 20px 35px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #ee8361;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 35px;
}
.block-contact_address_btn:hover {
  color: #faae48;
  cursor: pointer;
}
@media (max-width: 639px) {
  .block-contact_address_btn {
    margin-bottom: 0;
    font-size: 14px;
    padding: 17px 30px;
  }
}
.block-contact_address ul {
  margin-left: 0;
}
.block-contact_address ul li {
  list-style: none;
  font-family: "Work Sans", sans-serif;
  font-size: 15.5px;
  color: #fafafa;
  line-height: 26px;
  margin-bottom: 20px;
  display: flex;
}
@media (max-width: 639px) {
  .block-contact_address ul li {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 10px;
  }
}
.block-contact_address ul li i {
  margin-right: 14px;
  vertical-align: middle;
  background-repeat: no-repeat;
}
@media (max-width: 639px) {
  .block-contact_address ul li i {
    margin-right: 20px;
  }
}
.block-contact_address ul li address {
  display: inline;
  font-style: inherit;
}
.block-contact_map {
  width: 50%;
}
@media (max-width: 899px) {
  .block-contact_map {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .block-contact_map {
    width: 100%;
  }
}
.block-contact_map #map {
  height: 475px;
}
@media (max-width: 639px) {
  .block-contact_map #map {
    height: 300px;
  }
}

.block-page-title {
  padding: 45px 0;
}
@media (max-width: 639px) {
  .block-page-title {
    padding: 40px 25px;
  }
}
.block-page-title.visible .block-page-title_h1,
.block-page-title.visible .block-page-title_baseline {
  transform: none;
  opacity: 1;
}
.block-page-title_h1 {
  font-family: "Chapman", sans-serif;
  font-size: 40px;
  color: #000;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 56px;
  margin-bottom: 0;
  font-weight: bolder;
  transform: translateX(-50%);
  transition: all 0.7s;
  opacity: 0;
}
@media (max-width: 639px) {
  .block-page-title_h1 {
    font-size: 32px;
    letter-spacing: 0.2px;
    line-height: 44px;
    text-align: center;
  }
}
.block-page-title_baseline {
  margin-top: 20px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
  line-height: 30px;
  margin-bottom: 0;
  transform: translateX(-50%);
  transition: all 0.8s;
  opacity: 0;
}
@media (max-width: 639px) {
  .block-page-title_baseline {
    font-size: 15px;
    line-height: 28px;
  }
}

.block-image-text-default {
  max-width: 1266px;
  margin: 0 auto;
  display: flex;
  min-height: 550px;
}
.block-image-text-default.visible .block-image-text-default_image-container,
.block-image-text-default.visible .block-image-text-default_text-container {
  transform: none;
}
.block-image-text-default.visible .block-image-text-default_s-container svg {
  transform: none;
}
@media (max-width: 899px) {
  .block-image-text-default {
    flex-direction: column;
  }
}
@media (max-width: 639px) {
  .block-image-text-default {
    min-height: auto;
    flex-direction: column;
    margin-left: -25px;
    margin-right: -25px;
  }
}
.block-image-text-default_opacity {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #DDC4AD;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
}
.block-image-text-default_title-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 10%;
}
@media (max-width: 899px) {
  .block-image-text-default_title-container {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .block-image-text-default_title-container {
    width: 100%;
    padding: 50px 25px;
  }
}
.block-image-text-default_title p {
  font-family: "Chapman", sans-serif;
  font-size: 40px;
  color: #fff;
  letter-spacing: 0.1px;
  line-height: 56px;
  margin: 0;
}
@media (max-width: 639px) {
  .block-image-text-default_title p {
    font-size: 32px;
    letter-spacing: 0.2px;
    line-height: 44px;
  }
}
.block-image-text-default_image-container {
  width: 50%;
  position: relative;
  transform: translateX(-100%);
  transition: all 0.8s;
}
@media (max-width: 899px) {
  .block-image-text-default_image-container {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .block-image-text-default_image-container {
    width: 100%;
  }
}
.block-image-text-default_image-container img {
  object-fit: cover;
}
.block-image-text-default_text-container {
  width: 50%;
  background-color: #fff;
  padding: 70px 80px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(100%);
  transition: all 0.8s;
}
.block-image-text-default_text-container p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000000;
  line-height: 30px;
  margin-bottom: 10px;
}
@media (max-width: 899px) {
  .block-image-text-default_text-container {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .block-image-text-default_text-container {
    width: 100%;
    padding: 60px 25px;
  }
}
.block-image-text-default_s-container {
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #14a2b6;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 639px) {
  .block-image-text-default_s-container {
    padding: 50px 25px;
  }
}
.block-image-text-default_s-container svg {
  fill: #faae48;
  transform: rotate(-180deg);
  transition: all 0.6s linear;
}
@media (max-width: 639px) {
  .block-image-text-default_s-container svg {
    width: 261px;
    height: 300px;
  }
}

.list-blocks-s {
  margin: 200px auto 60px;
  max-width: 1266px;
}
.list-blocks-s.visible .list-blocks-s_title,
.list-blocks-s.visible .list-blocks-s_item {
  transform: none;
  opacity: 1;
}
@media (max-width: 639px) {
  .list-blocks-s {
    margin: 100px 0 30px;
    max-width: 100%;
  }
}
.list-blocks-s_title {
  font-family: "Work Sans", serif;
  font-size: 20px;
  color: #000;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  transform: translateX(-50%);
  transition: all 0.6s;
  opacity: 0;
}
@media (max-width: 639px) {
  .list-blocks-s_title {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 27px;
    text-align: left;
    margin-bottom: 15px;
  }
}
.list-blocks-s_item-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .list-blocks-s_item-container {
    flex-direction: column;
    margin-left: -25px;
    margin-right: -25px;
  }
}
.list-blocks-s_item {
  max-width: 402px;
  height: 553px;
  position: relative;
  margin: 0 15px 20px;
  transform: translateY(50%);
  transition: all 0.6s;
  opacity: 0;
}
.list-blocks-s_item:first-child .list-blocks-s_item_s-container {
  transform: rotate(19deg);
}
.list-blocks-s_item:nth-child(2n) {
  transform: translateY(80%);
}
.list-blocks-s_item:nth-child(2n) .list-blocks-s_item_s-container {
  transform: rotate(136deg);
}
.list-blocks-s_item:last-child {
  margin-right: 0;
}
.list-blocks-s_item:last-child .list-blocks-s_item_s-container {
  transform: rotate(349deg);
}
.list-blocks-s_item:first-child {
  margin-left: 0;
}
@media (max-width: 899px) {
  .list-blocks-s_item {
    margin: 0 0 20px;
  }
}
@media (max-width: 639px) {
  .list-blocks-s_item {
    max-width: 100%;
    height: 470px;
  }
}
.list-blocks-s_item_wave-container {
  background-image: url("assets/pictures/block-wave-background-red.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 639px) {
  .list-blocks-s_item_wave-container {
    top: 30%;
    height: calc(70% - 30px);
    padding-top: 30px;
  }
}
.list-blocks-s_item_s-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
}
@media (max-width: 639px) {
  .list-blocks-s_item_s-container {
    top: 5%;
  }
}
.list-blocks-s_item_s-container svg {
  fill: #faae48;
  width: 116px;
  height: 125px;
}
.list-blocks-s_item_img-container img {
  object-fit: cover;
}
.list-blocks-s_item_text-container {
  padding: 0 54px 50px;
}
@media (max-width: 639px) {
  .list-blocks-s_item_text-container {
    padding: 10px 25px 30px;
    height: calc(100% - 85px);
    display: flex;
    align-items: center;
  }
}
.list-blocks-s_item_text-container p {
  font-family: "Chapman", sans-serif;
  font-size: 34px;
  color: #fff;
  letter-spacing: 0.1px;
  line-height: 48px;
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .list-blocks-s_item_text-container p {
    font-size: 32px;
    letter-spacing: 0.2px;
    line-height: 44px;
  }
}
.list-blocks-s_item:nth-child(2n) .list-blocks-s_item_wave-container {
  background-image: url("assets/pictures/block-wave-background-orange.svg");
}
.list-blocks-s_item:nth-child(2n) .list-blocks-s_item_s-container svg {
  fill: #14a2b6;
}
.list-blocks-s_item:nth-child(3n) .list-blocks-s_item_wave-container {
  background-image: url("assets/pictures/block-wave-background-pink.svg");
}
.list-blocks-s_item:nth-child(3n) .list-blocks-s_item_s-container svg {
  fill: #ee8361;
}

.list-card-abonnement {
  max-width: 1266px;
  margin: 0 auto 78px auto;
  display: flex;
  justify-content: center;
  color: #fff;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .list-card-abonnement {
    margin: 0 0 20px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
.list-card-abonnement.white {
  color: #000;
}
.list-card-abonnement.white .list-card-abonnement_item {
  background-color: #fff;
}
.list-card-abonnement_item {
  display: flex;
  flex-direction: column;
  width: 294px;
  margin: 15px;
}
.list-card-abonnement_item.animate-type-pricing {
  transition: all 0.6s ease-in;
  opacity: 0;
}
.list-card-abonnement_item.animate-type-pricing:first-child {
  transform: translateY(150%);
}
.list-card-abonnement_item.animate-type-pricing:nth-child(2) {
  transform: translateY(100%);
}
.list-card-abonnement_item.animate-type-pricing:last-child {
  transform: translateY(50%);
}
.list-card-abonnement_item.animate-type-pricing.visible {
  transform: none;
  opacity: 1;
}
.list-card-abonnement_item:first-child {
  margin-left: 0;
}
.list-card-abonnement_item:last-child {
  margin-right: 0;
}
@media (max-width: 639px) {
  .list-card-abonnement_item {
    margin: 0 0 45px;
  }
}
.list-card-abonnement_item_head {
  border-bottom: 2px solid rgba(232, 232, 232, 0.7);
  padding: 20px;
  position: relative;
}
.list-card-abonnement_item_head_corner {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-left: 38px solid #ddc4ad;
  border-bottom: 38px solid transparent;
}
.list-card-abonnement_item_head_title {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  text-align: center;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  line-height: 40px;
}
.list-card-abonnement_item_head_subtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 0;
}
.list-card-abonnement_item_body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 20px;
}
.list-card-abonnement_item_body_price {
  padding-top: 5px;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 28px;
  margin-bottom: 0;
  text-align: center;
}
.list-card-abonnement_item_body_price .ht {
  font-size: 50px;
  text-align: right;
}
.list-card-abonnement_item_body_description {
  padding: 0 30px 20px 20px;
}
.list-card-abonnement_item_body_description p {
  margin-bottom: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 26px;
}
.list-card-abonnement_item_bottom {
  padding-top: 15px;
  margin-bottom: -20px;
  display: flex;
  justify-content: center;
}
.list-card-abonnement_item_bottom_btn {
  background-color: #fff;
  padding: 9px 20px;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  font-weight: bold;
}

.list-card-advantages {
  max-width: 1266px;
  margin: 122px auto 150px auto;
}
.list-card-advantages_title-section {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 56px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}
@media (max-width: 639px) {
  .list-card-advantages_title-section {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 27px;
  }
}
.list-card-advantages_list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .list-card-advantages_list {
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
  }
}
.list-card-advantages_item {
  width: 294px;
  margin: 0 15px;
  background-color: #fff;
  transition: all 0.6s ease-in;
  opacity: 0;
}
.list-card-advantages_item:first-child {
  margin-left: 0;
  transform: translateY(250%);
}
.list-card-advantages_item:nth-child(2) {
  transform: translateY(200%);
}
.list-card-advantages_item:nth-child(3) {
  transform: translateY(150%);
}
.list-card-advantages_item:last-child {
  transform: translateY(100%);
  margin-right: 0;
}
.list-card-advantages_item.visible {
  transform: none;
  opacity: 1;
}
@media (max-width: 899px) {
  .list-card-advantages_item {
    margin-bottom: 40px;
  }
}
@media (max-width: 639px) {
  .list-card-advantages_item {
    margin: 0 0 40px;
  }
}
.list-card-advantages_item_text-container {
  padding: 30px;
}
.list-card-advantages_item_title {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 40px;
  margin-bottom: 15px;
}
.list-card-advantages_item_description {
  color: #000000;
}
.list-card-advantages_item_description p {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 26px;
}
.list-card-advantages_item_description ul {
  margin-left: 0;
}
.list-card-advantages_item_description ul li {
  list-style: none;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: 10px;
}
.list-card-advantages_item_description ul li:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 10px;
  margin-right: 9px;
  background-image: url("assets/icons/tick-orange.svg");
}

.list-block-image-text-dot {
  margin: 200px auto 100px;
  max-width: 1266px;
}
.list-block-image-text-dot .section-title.align-left {
  text-align: left;
}
@media (max-width: 639px) {
  .list-block-image-text-dot_container {
    margin-left: -25px;
    margin-right: -25px;
  }
}
.list-block-image-text-dot_item {
  display: flex;
  max-width: 100%;
  margin-bottom: 20px;
  position: relative;
}
.list-block-image-text-dot_item.visible .list-block-image-text-dot_item_image,
.list-block-image-text-dot_item.visible .list-block-image-text-dot_item_text {
  transform: none;
  opacity: 1;
}
@media (max-width: 899px) {
  .list-block-image-text-dot_item {
    flex-direction: column;
  }
}
@media (max-width: 639px) {
  .list-block-image-text-dot_item {
    flex-direction: column;
  }
}
.list-block-image-text-dot_item_image {
  width: 50%;
  position: relative;
  transform: translateX(-100%);
  transition: all 0.8s;
  opacity: 0;
}
@media (max-width: 899px) {
  .list-block-image-text-dot_item_image {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .list-block-image-text-dot_item_image {
    width: 100%;
  }
}
.list-block-image-text-dot_item_image img {
  display: block;
  object-fit: fill;
}
.list-block-image-text-dot_item_opacity-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #DDC4AD;
  mix-blend-mode: multiply;
}
.list-block-image-text-dot_item_title-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.list-block-image-text-dot_item_title {
  font-family: "Chapman", sans-serif;
  font-size: 40px;
  color: #fff;
  letter-spacing: 0.1px;
  line-height: 56px;
  text-align: center;
  margin: 0;
}
@media (max-width: 639px) {
  .list-block-image-text-dot_item_title {
    font-size: 32px;
    line-height: 44px;
  }
}
.list-block-image-text-dot_item_text {
  width: 50%;
  padding: 50px 70px;
  background-color: #fff;
  transform: translateX(100%);
  transition: all 0.8s;
  opacity: 0;
}
.list-block-image-text-dot_item_text p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
}
.list-block-image-text-dot_item_text p:last-child {
  margin-bottom: 0;
}
@media (max-width: 899px) {
  .list-block-image-text-dot_item_text {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .list-block-image-text-dot_item_text {
    width: 100%;
    padding: 30px 25px;
    font-size: 15px;
    line-height: 28px;
  }
}
.list-block-image-text-dot_item_circle-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.list-block-image-text-dot_item_circle {
  width: 66px;
  height: 66px;
  border-radius: 66px;
  display: block;
  margin-top: -45px;
}
@media (max-width: 639px) {
  .list-block-image-text-dot_item_circle {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    bottom: -25px;
    left: auto;
    right: -25px;
  }
}

.faq_list-container {
  display: flex;
  margin: 0 auto;
  max-width: 835px;
  flex-direction: column;
}
@media (max-width: 639px) {
  .faq_list-container {
    flex-direction: column;
    margin-left: -25px;
    margin-right: -25px;
  }
}
.faq_item-container {
  background-color: #fff;
  padding: 50px 100px;
  margin-bottom: 30px;
  position: relative;
}
@media (max-width: 639px) {
  .faq_item-container {
    padding: 20px 60px 25px 20px;
    margin-bottom: 20px;
  }
}
.faq_item-container.response-open .faq_item_response_container {
  opacity: 1;
  display: block;
}
.faq_item-container.response-open .faq_item_arrow {
  background-image: url("assets/icons/arrow-white-up.svg");
}
.faq_item-container:first-child .faq_item_circle {
  background-color: #f8b9aa;
}
.faq_item-container:nth-child(2n) .faq_item_circle {
  background-color: #1584c3;
}
.faq_item-container:nth-child(3n) .faq_item_circle {
  background-color: #ee8361;
}
.faq_item-container:nth-child(4n) .faq_item_circle {
  background-color: #faae48;
}
.faq_item_title {
  font-family: "Chapman", sans-serif;
  font-size: 24px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 36px;
  font-weight: bold;
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .faq_item_title {
    font-size: 20px;
    letter-spacing: 0.1px;
    line-height: 28px;
  }
}
.faq_item_response_container {
  display: none;
  opacity: 0;
  padding: 40px 0 10px;
  transition: all 0.6s ease-in;
}
.faq_item_response_container p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.3px;
}
.faq_item_circle {
  width: 66px;
  height: 66px;
  border-radius: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 33px;
  right: -33px;
  z-index: 2;
  background-color: #ddc4ad;
}
.faq_item_circle:hover {
  cursor: pointer;
}
@media (max-width: 639px) {
  .faq_item_circle {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    top: calc(50% - 16px);
    right: 10px;
  }
}
.faq_item_arrow {
  background-image: url("assets/icons/arrow-white-down.svg");
  width: 19px;
  height: 12px;
  display: inline-block;
}

.hero {
  position: relative;
  height: calc(100vh - 60px);
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 50px;
  margin: 0 -50px;
}
.hero.visible .hero_title {
  opacity: 1;
}
@media (max-width: 639px) {
  .hero {
    margin-left: -25px;
    margin-right: -25px;
    height: calc(80vh - 60px);
  }
}
.hero_text-container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  padding: 30px 50px;
}
@media (max-width: 639px) {
  .hero_text-container {
    padding: 20px 25px;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
}
.hero_overtitle {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.1px;
  line-height: 56px;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  top: 30px;
  left: 50px;
}
@media (max-width: 639px) {
  .hero_overtitle {
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 27px;
    position: static;
  }
}
.hero_title {
  font-family: "Chapman", sans-serif;
  font-size: 40px;
  color: #fff;
  letter-spacing: 0.1px;
  line-height: 56px;
  font-weight: bold;
  max-width: 540px;
  opacity: 0;
  transition: all 0.8s ease-in;
}
.hero_title.left {
  max-width: 540px;
}
.hero_title.center {
  max-width: 800px;
  margin: 0 auto;
  display: block;
}
.hero_title.right {
  max-width: 540px;
}
@media (max-width: 639px) {
  .hero_title {
    font-size: 32px;
    letter-spacing: 0.3px;
    line-height: 44px;
  }
}

.block-image-text-tongue {
  max-width: 1266px;
  margin: 0 auto;
  display: flex;
  position: relative;
  padding-top: 300px;
}
@media (max-width: 639px) {
  .block-image-text-tongue {
    padding-top: 200px;
    flex-direction: column;
    margin-left: -25px;
    margin-right: -25px;
    overflow: hidden;
  }
}
@media (max-width: 639px) {
  .block-image-text-tongue.left {
    margin-bottom: 60px;
  }
}
.block-image-text-tongue.right {
  flex-direction: row-reverse;
}
@media (max-width: 639px) {
  .block-image-text-tongue.right {
    flex-direction: column;
  }
}
.block-image-text-tongue.right .block-image-text-tongue_background {
  left: auto;
  right: -150%;
}
@media (max-width: 639px) {
  .block-image-text-tongue.right .block-image-text-tongue_background {
    right: 90px;
    top: -370px;
  }
}
.block-image-text-tongue.right .block-image-text-tongue_background svg {
  transform: scale(1, 1);
}
.block-image-text-tongue_background {
  position: absolute;
  top: -100%;
  left: -150%;
  z-index: 2;
}
@media (max-width: 639px) {
  .block-image-text-tongue_background {
    top: -300px;
    left: -770px;
    max-width: 100%;
  }
}
.block-image-text-tongue_background svg {
  transform: scale(-1, 1);
}
.block-image-text-tongue_text-container {
  margin: 0 11%;
  max-width: 402px;
  z-index: 2;
}
@media (max-width: 639px) {
  .block-image-text-tongue_text-container {
    margin: 0 25px;
    max-width: 100%;
  }
}
.block-image-text-tongue_title {
  font-family: "Chapman", sans-serif;
  font-size: 40px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 56px;
  font-weight: bold;
  margin-bottom: 15px;
}
@media (max-width: 639px) {
  .block-image-text-tongue_title {
    font-size: 24px;
    letter-spacing: 0.1px;
    line-height: 36px;
  }
}
.block-image-text-tongue_text p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.3px;
}
@media (max-width: 639px) {
  .block-image-text-tongue_text p {
    font-size: 15px;
    line-height: 28px;
  }
}
.block-image-text-tongue_image-container {
  margin-top: 10%;
  z-index: 2;
}

.hero + .block-image-text-tongue {
  padding-top: 170px;
}
@media (max-width: 639px) {
  .hero + .block-image-text-tongue {
    margin-top: -150px;
  }
}

.card-image-text {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  max-width: 294px;
}
@media (max-width: 639px) {
  .card-image-text {
    margin: 15px 0;
    max-width: 100%;
  }
}
.card-image-text:first-child {
  margin-left: 0;
}
.card-image-text:last-child {
  margin-right: 0;
}
.card-image-text_img {
  margin-bottom: 25px;
}
.card-image-text_title {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 40px;
  margin-bottom: 15px;
}
.card-image-text_description p {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #000;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin-bottom: 0;
}

.card-membre_item {
  width: 211px;
  height: 183px;
  margin: 15px;
  position: relative;
}
.card-membre_item a {
  color: #fff;
  position: relative;
  display: block;
}
.card-membre_item a:hover {
  color: #fff;
}
.card-membre_item:hover .card-membre_title {
  height: 150px;
}
.card-membre_item:hover .card-membre_title_job {
  height: 20%;
  opacity: 1;
}
.card-membre_photo {
  background-color: #D6DAE0;
  width: 211px;
  height: 183px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.card-membre_title {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 12px 0;
  text-align: center;
  background-color: rgba(248, 185, 170, 0.9);
  height: 20px;
  width: 100%;
  transition: height 1s;
}
.card-membre_title--melon-salmon {
  background-color: rgba(248, 185, 170, 0.9);
}
.card-membre_title--sand-melon {
  background-color: rgba(221, 196, 173, 0.9);
}
.card-membre_title--blue-melon {
  background-color: rgba(20, 162, 182, 0.9);
}
.card-membre_title--green-melon {
  background-color: rgba(20, 162, 182, 0.9);
}
.card-membre_title--orange-melon {
  background-color: rgba(250, 174, 72, 0.9);
}
.card-membre_title--salmon-melon {
  background-color: rgba(238, 131, 97, 0.9);
}
.card-membre_title--red-melon {
  background-color: rgba(227, 90, 67, 0.9);
}
.card-membre_title_name {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
  padding: 0 12px;
}
@media (max-width: 639px) {
  .card-membre_title_name {
    font-size: 12px;
    letter-spacing: 0.3px;
    line-height: 22px;
  }
}
.card-membre_title_job {
  margin-top: 12px;
  margin-bottom: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 20px;
  height: 0;
  opacity: 0;
  transition: height 1s, opacity 1s;
  padding: 0 12px;
}
@media (max-width: 639px) {
  .card-membre_title_job {
    line-height: 19px;
  }
}

.membre-filter {
  margin-top: 40px;
  margin-right: 50px;
  min-width: 325px;
}
.membre-filter_erase {
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}
.membre-filter_erase:hover {
  color: #fff;
  text-decoration: underline;
}
.membre-filter_erase i {
  background-size: cover;
  width: 18px;
  height: 14px;
  vertical-align: sub;
  margin-right: 8px;
}
@media (max-width: 639px) {
  .membre-filter {
    opacity: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    min-width: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 5;
    overflow: scroll;
    transition: all 0.5s ease-in;
  }
}
.membre-filter_btn-mobile {
  background-color: #14a2b6;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.2px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 12px;
  color: #fff;
  margin-left: 25px;
  display: none;
}
@media (max-width: 639px) {
  .membre-filter_btn-mobile {
    display: block;
  }
}
.membre-filter_btn-mobile svg {
  vertical-align: sub;
  margin-right: 5px;
}
.membre-filter.opened {
  right: 0;
  opacity: 1;
}
.membre-filter_head-mobile {
  background-color: #14a2b6;
  padding: 25px;
  border-bottom: 1px solid white;
  display: none;
  justify-content: space-between;
}
@media (max-width: 639px) {
  .membre-filter_head-mobile {
    display: flex;
  }
}
.membre-filter_head-mobile_title {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.3px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
}
.membre-filter_head-mobile_title svg {
  vertical-align: middle;
  margin-right: 10px;
}
.membre-filter_top-background {
  height: 202px;
}
@media (max-width: 639px) {
  .membre-filter_top-background {
    display: none;
  }
}
.membre-filter_top-background svg {
  fill: #14a2b6;
}
.membre-filter_list {
  padding: 0 50px 50px;
  background-color: #14a2b6;
  min-width: 325px;
}
@media (max-width: 639px) {
  .membre-filter_list {
    padding: 30px 25px;
    min-width: auto;
  }
}
.membre-filter_list.fixed {
  padding-top: 50px;
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100vh - 60px);
  overflow: scroll;
}
.membre-filter_title {
  margin-top: -18px;
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  letter-spacing: 0.1px;
  line-height: 56px;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 639px) {
  .membre-filter_title {
    display: none;
  }
}
.membre-filter label {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  color: #fafafa;
  letter-spacing: 0.3px;
  line-height: 26px;
  font-weight: 600;
}
.membre-filter label input[type=checkbox] {
  width: 0;
  height: 0;
  margin: 0;
}
.membre-filter label input[type=checkbox]:checked + .fake-checkbox {
  background-color: white;
}
.membre-filter label .fake-checkbox {
  height: 12px;
  width: 12px;
  border: 1px solid white;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

.block-testimonies-slider {
  margin: -50px auto 100px;
  max-width: 1266px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 639px) {
  .block-testimonies-slider {
    margin-left: -25px;
    margin-right: -25px;
  }
}
.block-testimonies-slider .slick-track {
  display: flex;
}
.block-testimonies-slider .slick-list {
  padding-top: 50px;
}
.block-testimonies-slider_item {
  background-color: #ee8361;
  padding: 70px;
  position: relative;
  height: auto;
  float: none;
  transform: translateX(-100%);
  transition: all 0.6s;
  opacity: 0;
}
.block-testimonies-slider_item.visible {
  transform: none;
  opacity: 1;
}
.block-testimonies-slider_item.visible .block-testimonies-slider_item_svg {
  transform: none;
  opacity: 1;
}
.block-testimonies-slider_item .block-testimonies-slider_item_svg {
  transform: translateX(-100%);
  transition: all 0.8s;
  opacity: 0;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item {
    padding: 50px 20px;
    width: 100%;
    min-height: 1px !important;
  }
}
.block-testimonies-slider_item_svg {
  position: absolute;
  left: calc(50% - 55px);
  top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_svg {
    left: auto;
    right: 13px;
  }
}
.block-testimonies-slider_item svg .a {
  fill: #14a2b6;
}
.block-testimonies-slider_item:nth-child(2n) {
  background-color: #f8b9aa;
}
.block-testimonies-slider_item:nth-child(2n) svg .a {
  fill: #e35a43;
}
.block-testimonies-slider_item_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.block-testimonies-slider_item_quote {
  margin-bottom: 50px;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_quote {
    margin-bottom: 30px;
  }
}
.block-testimonies-slider_item_quote p {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 36px;
  margin-bottom: 0;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_quote p {
    font-size: 16px;
    line-height: 29px;
  }
}
.block-testimonies-slider_item_member {
  display: flex;
  align-items: center;
}
.block-testimonies-slider_item_member_photo {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 30px;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_member_photo {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    margin-right: 20px;
  }
}
.block-testimonies-slider_item_member_text {
  color: #000;
  width: calc(100% - 130px);
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_member_text {
    width: calc(100% - 80px);
  }
}
.block-testimonies-slider_item_member_name {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  letter-spacing: 0.1px;
  line-height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_member_name {
    font-size: 24px;
    line-height: 30px;
  }
}
.block-testimonies-slider_item_member_job {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 27px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 639px) {
  .block-testimonies-slider_item_member_job {
    font-size: 13px;
    line-height: 21px;
  }
}
.block-testimonies-slider .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 0;
  margin-bottom: 0;
  padding-top: 30px;
}
.block-testimonies-slider .slick-dots li {
  list-style: none;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 3px solid #14a2b6;
  margin-right: 20px;
}
.block-testimonies-slider .slick-dots li.slick-active {
  background-color: #14a2b6;
}
.block-testimonies-slider .slick-dots li:hover {
  cursor: pointer;
}
.block-testimonies-slider .slick-dots li:last-child {
  margin-right: 0;
}
.block-testimonies-slider .slick-dots li button {
  display: none;
}

.tease-post {
  max-width: 402px;
  padding: 50px 55px;
  background-color: #ee8361;
  margin-top: 125px;
  margin-right: 30px;
  margin-bottom: 50px;
}
@media (max-width: 639px) {
  .tease-post {
    margin-right: 0;
    margin-bottom: 30px;
    max-width: 100%;
  }
}
@media (max-width: 639px) {
  .tease-post .card-article_s {
    display: none;
  }
}
.tease-post .card-article_s svg {
  fill: #f8b9aa;
  transform: rotate(30deg);
}
.tease-post:nth-child(2) {
  background-color: #f8b9aa;
}
.tease-post:nth-child(2) .card-article_s svg {
  fill: #ee8361;
  transform: rotate(20deg);
}
.tease-post:nth-child(3) {
  background-color: #faae48;
}
.tease-post:nth-child(3) .card-article_s svg {
  fill: #f8b9aa;
  transform: rotate(60deg);
}
.tease-post:nth-child(4) {
  background-color: #ddc4ad;
}
.tease-post:nth-child(4) .card-article_s svg {
  fill: #ee8361;
  transform: rotate(80deg);
}

.card-article a {
  color: #000;
}
.card-article a:hover {
  color: #000;
}
.card-article_s {
  position: absolute;
  top: 80px;
  right: 0;
}
.card-article_s svg {
  width: 97.44px;
  height: 87.27px;
}
.card-article_image-container {
  width: 290px;
  margin-top: -170px;
  margin-bottom: 30px;
  position: relative;
}
@media (max-width: 639px) {
  .card-article_image-container {
    display: flex;
    justify-content: center;
  }
}
.card-article_image-container img {
  width: 248px;
  height: 248px;
}
.card-article_title {
  font-family: "Chapman", sans-serif;
  font-size: 24px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 36px;
  margin-bottom: 20px;
  font-weight: bold;
}
.card-article_resume {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #000;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin-bottom: 0;
}
.card-article_link {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #000;
  text-align: right;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin-bottom: 0;
}

.btn-default-flat {
  height: 60px;
  background-color: #fff;
  font-family: "Work Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #14a2b6;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 20px 30px;
  text-transform: uppercase;
}
@media (max-width: 639px) {
  .btn-default-flat {
    font-size: 14px;
  }
}
.btn-default-flat:hover {
  color: #faae48;
  cursor: pointer;
}
.btn-default-flat.small {
  height: 50px;
  padding: 17px 21px;
}
.btn-default-flat.red {
  color: #e35a43;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.single.visible .post-single_backlink-container,
.single.visible .post-single_head,
.single.visible .post-single_body,
.single.visible .post-single_head_title_tongue_background,
.single.visible .blog-author,
.single.visible .article-h1,
.single.visible .single-members_job {
  transform: none;
  opacity: 1;
}

.post-single {
  padding-top: 100px;
  display: flex;
  position: relative;
  max-width: 1266px;
}
@media (max-width: 639px) {
  .post-single {
    margin: 0 -25px;
    flex-direction: column;
  }
}
.post-single .post-type-post {
  max-width: 1266px;
  margin: 0 auto;
}
.post-single_left-column {
  width: 26%;
  max-width: 294px;
  margin-right: 30px;
}
@media (max-width: 639px) {
  .post-single_left-column {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    padding: 25px 25px 70px;
    margin-top: 40px;
  }
}
.post-single_right-column {
  width: 74%;
  max-width: 834px;
}
@media (max-width: 639px) {
  .post-single_right-column {
    width: 100%;
    max-width: 100%;
  }
}
.post-single_backlink-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 1266px;
  margin: 0 auto;
  padding: 20px 50px;
  opacity: 0;
  transform: translateY(-50px);
  transition: all 0.6s ease-in;
}
@media (max-width: 639px) {
  .post-single_backlink-container {
    z-index: 2;
  }
}
.post-single_backlink-container a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  background-color: #fff;
  transition: all 0.5s;
}
.post-single_backlink-container a svg {
  transition: all 0.5s;
}
.post-single_backlink-container a:hover {
  transform: scale(1.1);
}
.post-single_backlink-container a:hover svg {
  transform: scale(1.1);
}
.post-single_head {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 0.6s ease-in;
}
.post-single_head_feature {
  width: 294px;
  height: auto;
  margin-right: 30px;
}
@media (max-width: 639px) {
  .post-single_head_feature {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}
.post-single_head_title {
  height: 294px;
  padding: 86px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 639px) {
  .post-single_head_title {
    padding: 50px 25px;
    background-color: #ee8361;
  }
}
.post-single_head_title_tongue_background {
  position: absolute;
  top: -180%;
  left: 0;
  z-index: 2;
  transform: translateX(100%) translateY(-100%);
  opacity: 0;
  transition: all 0.6s ease-in;
}
.post-single_head_title_tongue_background svg {
  opacity: 85%;
}
@media (max-width: 639px) {
  .post-single_head_title_tongue_background {
    display: none;
  }
}
.post-single .article-h1 {
  font-family: "Chapman", sans-serif;
  font-size: 40px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 56px;
  position: relative;
  z-index: 3;
  opacity: 0;
  transition: all 0.8s ease-in;
}
@media (max-width: 639px) {
  .post-single .article-h1 {
    font-size: 24px;
    line-height: 36px;
  }
}
.post-single .blog-author {
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  color: #000;
  letter-spacing: 0.2px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 0;
  position: relative;
  z-index: 3;
  opacity: 0;
  transition: all 0.8s ease-in;
}
@media (max-width: 639px) {
  .post-single .blog-author {
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
  }
}
.post-single .post-single_body {
  max-width: 834px;
  background-color: #fff;
  padding: 90px 100px 130px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.6s ease-in;
}
@media (max-width: 639px) {
  .post-single .post-single_body {
    max-width: 100%;
    padding: 40px 25px;
  }
}
.post-single .post-single_body_intro {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 32px;
  letter-spacing: 0.1px;
  margin-top: 30px;
}
@media (max-width: 639px) {
  .post-single .post-single_body_intro {
    font-size: 18px;
    line-height: 32px;
  }
}
.post-single .post-single_body h2 {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 40px;
  margin-bottom: 30px;
}
@media (max-width: 639px) {
  .post-single .post-single_body h2 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}
.post-single .post-single_body p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.1px;
}
@media (max-width: 639px) {
  .post-single .post-single_body p {
    font-size: 15px;
    line-height: 28px;
  }
}
.post-single .post-single_body p + h2 {
  margin-top: 50px;
}
.post-single .post-single_body p.intro {
  font-size: 18px;
  line-height: 32px;
}
.post-single .post-single_body p.bottom-line {
  margin: 60px 0 0;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #959595;
  letter-spacing: 0.2px;
  line-height: 21px;
}
.post-single .post-single_body ul li {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
}
@media (max-width: 639px) {
  .post-single .post-single_body ul li {
    font-size: 15px;
    line-height: 28px;
  }
}
.post-single .post-single_body a {
  color: #ee8361;
  font-weight: bold;
}
.post-single .post-single_body a:hover {
  text-decoration: underline;
}
.post-single .post-single_body b {
  font-weight: bold;
}
.post-single .post-single_body blockquote {
  margin: 55px 0 50px 0;
  border: none;
  position: relative;
  padding: 20px 0 0 0;
}
.post-single .post-single_body blockquote:before {
  content: "";
  position: absolute;
  top: -30px;
  left: calc(50% - 15px);
  background-image: url("assets/icons/guillemets.svg");
  width: 52px;
  height: 39px;
}
.post-single .post-single_body blockquote p {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  color: #ee8361;
  letter-spacing: 0.1px;
  line-height: 40px;
  font-weight: bold;
}
@media (max-width: 639px) {
  .post-single .post-single_body blockquote p {
    font-size: 24px;
    line-height: 36px;
  }
}

.article-author {
  max-width: 834px;
  width: 100%;
  padding: 70px 105px;
  margin-top: 140px;
  background-color: #ee8361;
}
@media (max-width: 639px) {
  .article-author {
    max-width: 100%;
    margin-left: 0;
    padding: 50px 25px;
  }
}
.article-author.visible .article-author_avatar,
.article-author.visible .article-author_name,
.article-author.visible .article-author_job,
.article-author.visible .article-author_description {
  transform: none;
  opacity: 1;
}
.article-author_header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.article-author_avatar {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin-right: 30px;
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.4s ease-in;
}
.article-author_name {
  font-family: "Chapman", sans-serif;
  font-size: 28px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.5s ease-in;
}
@media (max-width: 639px) {
  .article-author_name {
    font-size: 24px;
    line-height: 30px;
  }
}
.article-author_job {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.2px;
  line-height: 27px;
  margin: 0;
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.6s ease-in;
}
@media (max-width: 639px) {
  .article-author_job {
    font-size: 13px;
    line-height: 21px;
  }
}
.article-author_description {
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.7s ease-in;
}
.article-author_description p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
  margin: 0;
}
@media (max-width: 639px) {
  .article-author_description p {
    font-size: 15px;
    line-height: 28px;
  }
}

.single-members {
  padding-bottom: 100px;
}
.single-members .post-type-membres {
  max-width: 1266px;
  margin: 0 auto;
}
.single-members--melon-salmon {
  background-color: rgb(248, 185, 170);
}
.single-members--melon-salmon .post-single_backlink svg .a {
  stroke: rgb(248, 185, 170);
  fill: transparent;
}
.single-members--melon-salmon .single-members_social_network svg {
  stroke: transparent;
  fill: rgb(248, 185, 170);
}
.single-members--melon-salmon .single-members_social button {
  background-color: #ee8361;
}
.single-members--melon-salmon .single-members_social button:hover {
  background-color: #D87759;
}
.single-members--melon-salmon .post-single_head_title_tongue_background svg {
  stroke: rgb(238, 131, 97);
  fill: rgb(238, 131, 97);
}
.single-members--sand-melon {
  background-color: rgb(221, 196, 173);
}
.single-members--sand-melon .post-single_backlink svg .a {
  stroke: rgb(221, 196, 173);
  fill: transparent;
}
.single-members--sand-melon .single-members_social_network svg {
  stroke: transparent;
  fill: rgb(221, 196, 173);
}
.single-members--sand-melon .single-members_social button {
  background-color: #ee8361;
}
.single-members--sand-melon .single-members_social button:hover {
  background-color: #D87759;
}
.single-members--sand-melon .post-single_head_title_tongue_background svg {
  stroke: #ee8361;
  fill: #ee8361;
}
.single-members--blue-melon {
  background-color: #1584c3;
}
.single-members--blue-melon .post-single_backlink svg .a {
  stroke: #1584c3;
  fill: transparent;
}
.single-members--blue-melon .single-members_social_network svg {
  stroke: transparent;
  fill: #1584c3;
}
.single-members--blue-melon .single-members_social button {
  background-color: rgb(248, 185, 170);
}
.single-members--blue-melon .single-members_social button:hover {
  background-color: #EAAEA0;
}
.single-members--blue-melon .post-single_head_title_tongue_background svg {
  stroke: rgb(248, 185, 170);
  fill: rgb(248, 185, 170);
}
.single-members--green-melon {
  background-color: rgb(20, 162, 182);
}
.single-members--green-melon .post-single_backlink svg .a {
  stroke: rgb(20, 162, 182);
  fill: transparent;
}
.single-members--green-melon .single-members_social_network svg {
  stroke: transparent;
  fill: rgb(20, 162, 182);
}
.single-members--green-melon .single-members_social button {
  background-color: #f8b9aa;
}
.single-members--green-melon .single-members_social button:hover {
  background-color: #EAAEA0;
}
.single-members--green-melon .post-single_head_title_tongue_background svg {
  stroke: #f8b9aa;
  fill: #f8b9aa;
}
.single-members--orange-melon {
  background-color: rgb(250, 174, 72);
}
.single-members--orange-melon .post-single_backlink svg .a {
  stroke: rgb(250, 174, 72);
  fill: transparent;
}
.single-members--orange-melon .single-members_social_network svg {
  stroke: transparent;
  fill: rgb(250, 174, 72);
}
.single-members--orange-melon .single-members_social button {
  background-color: #ee8361;
}
.single-members--orange-melon .single-members_social button:hover {
  background-color: #D87759;
}
.single-members--orange-melon .post-single_head_title_tongue_background svg {
  stroke: #ee8361;
  fill: #ee8361;
}
.single-members--salmon-melon {
  background-color: rgb(238, 131, 97);
}
.single-members--salmon-melon .post-single_backlink svg {
  stroke: rgb(238, 131, 97);
  fill: transparent;
}
.single-members--salmon-melon .single-members_social_network svg .a {
  stroke: transparent;
  fill: rgb(238, 131, 97);
}
.single-members--salmon-melon .single-members_social button {
  background-color: #f8b9aa;
}
.single-members--salmon-melon .single-members_social button:hover {
  background-color: #EAAEA0;
}
.single-members--salmon-melon .post-single_head_title_tongue_background svg {
  stroke: #f8b9aa;
  fill: #f8b9aa;
}
.single-members--red-melon {
  background-color: #e35a43;
}
.single-members--red-melon .post-single_backlink svg {
  stroke: #e35a43;
  fill: transparent;
}
.single-members--red-melon .single-members_social_network svg .a {
  stroke: transparent;
  fill: #e35a43;
}
.single-members--red-melon .post-single_head_title_tongue_background svg {
  stroke: #f8b9aa;
  fill: #f8b9aa;
}
.single-members--red-melon .single-members_social button {
  background-color: #f8b9aa;
}
.single-members--red-melon .single-members_social button:hover {
  background-color: #EAAEA0;
}
.single-members .post-single_head_title {
  height: 294px;
  margin-bottom: 50px;
}
.single-members_job {
  position: relative;
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 32px;
  margin-bottom: 0;
  z-index: 3;
  opacity: 0;
  transition: all 0.8s ease-in;
}
@media (max-width: 639px) {
  .single-members_job {
    font-size: 15px;
    line-height: 28px;
  }
}
.single-members_social {
  background-color: #fff;
  max-width: 294px;
}
.single-members_social_network {
  margin-left: 0;
  margin-top: -25px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-members_social_network .bg-black-circle {
  width: 50px;
  height: 50px;
  background-color: black;
  border-radius: 25px;
  display: block;
}
.single-members_social_network li {
  list-style: none;
  margin-right: 5px;
}
.single-members_social_network li:last-child {
  margin-right: 0;
}
.single-members_social_network li a {
  width: 50px;
  height: 50px;
  display: block;
  color: #fff;
}
.single-members_social_network li a span {
  transition: all 0.5s;
}
.single-members_social_network li a svg {
  width: 50px;
  height: 50px;
  transition: all 0.5s;
}
.single-members_social_network li a:hover svg, .single-members_social_network li a:hover span {
  transform: scale(1.1);
}
.single-members_social_contact {
  padding: 40px 0 35px;
  margin: 0 20px 5px 20px;
}
.single-members_social_contact li {
  list-style: none;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  color: #000;
  line-height: 23px;
  margin-bottom: 5px;
}
.single-members_social_contact li a {
  color: #000;
  vertical-align: middle;
}
.single-members_social_contact li a i {
  margin-right: 5px;
}
.single-members_social_contact li a:hover {
  color: #faae48;
}
.single-members_social button {
  padding: 20px 40px;
  background-color: #ee8361;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 26px;
  text-transform: uppercase;
  width: 100%;
  transition: background-color 0.3s;
}
@media (max-width: 639px) {
  .single-members_social button {
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 26px;
    padding: 17px 40px;
  }
}
.single-members_social button:hover {
  cursor: pointer;
}
@media (max-width: 639px) {
  .single-members .post-single_head_title {
    background-color: transparent;
    padding: 0 25px;
    height: auto;
    margin-bottom: 0;
  }
  .single-members .post-single_head_title_tongue_background {
    display: block;
    top: -260px;
    left: -75px;
  }
  .single-members .post-single_head_title_tongue_background svg {
    height: 440px;
    width: 700px;
    opacity: 100%;
  }
  .single-members .single-members_social {
    max-width: 100%;
  }
}

.admin-bar .homepage_hero {
  height: calc(100vh - 36px);
}
@media (max-width: 639px) {
  .admin-bar .homepage_hero {
    height: calc(100vh - 126px);
  }
}

.page-template-template-homepage .footer {
  margin-top: 0;
}

@media (max-width: 639px) {
  .homepage {
    padding-top: 115px;
  }
}
.homepage_hero {
  height: 100vh;
  position: relative;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 200px;
  /*&:before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #DDC4AD;
    mix-blend-mode: multiply;
  }*/
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 639px) {
  .homepage_hero {
    height: calc(100vh - 115px);
    margin-bottom: 130px;
  }
}
.homepage_hero_drop {
  position: absolute;
  left: -450px;
  bottom: -130px;
}
@media (max-width: 1023px) {
  .homepage_hero_drop {
    left: -300px;
    bottom: -150px;
  }
  .homepage_hero_drop svg {
    width: 780px;
  }
}
@media (max-width: 639px) {
  .homepage_hero_drop {
    left: -300px;
    bottom: -150px;
  }
  .homepage_hero_drop svg {
    width: 780px;
  }
}
.homepage_hero_drop svg {
  animation-delay: 0s, 3s;
  animation-name: reduce;
  animation-duration: 0.8s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
}
@keyframes reduce {
  from {
    transform: scale(10);
  }
  to {
    transform: none;
  }
}
.homepage_hero_text-container {
  position: absolute;
  left: 50px;
  bottom: 62px;
  max-width: 500px;
  animation-delay: 0s, 4s;
  animation-name: slideInRight;
  animation-duration: 0.8s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
}
@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@media (max-width: 1023px) {
  .homepage_hero_text-container {
    left: 15px;
    bottom: 50px;
  }
}
@media (max-width: 639px) {
  .homepage_hero_text-container {
    left: 25px;
    right: 25px;
    bottom: 50px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.homepage_hero_title {
  font-family: "Chapman", sans-serif;
  font-size: 58px;
  color: #292929;
  letter-spacing: 0.2px;
  line-height: 60px;
  font-weight: bold;
}
@media (max-width: 1023px) {
  .homepage_hero_title {
    line-height: 58px;
    font-size: 55px;
  }
}
@media (max-width: 639px) {
  .homepage_hero_title {
    letter-spacing: 0.3px;
    line-height: 44px;
    font-size: 32px;
    max-width: 308px;
    text-align: center;
    margin-bottom: 5px;
  }
}
.homepage_hero_baseline {
  font-family: "Work Sans", sans-serif;
  font-size: 22px;
  color: #292929;
  letter-spacing: 0.2px;
  line-height: 27px;
}
@media (max-width: 1023px) {
  .homepage_hero_baseline {
    line-height: 24px;
    font-size: 19px;
  }
}
@media (max-width: 639px) {
  .homepage_hero_baseline {
    letter-spacing: 0.3px;
    line-height: 21px;
    font-size: 13px;
    margin-top: 20px;
    max-width: 360px;
    text-align: center;
  }
}
.homepage_section-title {
  text-align: left;
  max-width: 1266px;
  margin-left: auto;
  margin-right: auto;
}
.homepage .block-image-text-default_title-container {
  background-color: transparent;
}
.homepage .block-image-text-default {
  margin-bottom: 150px;
}
@media (max-width: 639px) {
  .homepage .block-image-text-default {
    margin-bottom: 85px;
  }
}
@media (max-width: 639px) {
  .homepage .list-block-image-text-dot {
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.homepage_btn-discover {
  margin-top: 100px;
  margin-bottom: 80px;
}
@media (max-width: 639px) {
  .homepage_btn-discover {
    margin-bottom: 50px;
  }
}
.homepage_slider-members {
  width: 100%;
  padding: 80px 0 100px !important;
  height: 100%;
  overflow: visible !important;
}
.homepage_slider-members .swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.homepage_slider-members .swiper-slide.swiper-slide-active {
  transform: scale(1.1);
}
.homepage_slider-members .swiper-scrollbar {
  bottom: -50px !important;
  height: 66px !important;
  opacity: 1 !important;
  width: 100% !important;
  left: 0 !important;
  background-color: transparent !important;
}
.homepage_slider-members .swiper-scrollbar:before {
  content: "";
  position: absolute;
  bottom: 33px;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid rgba(20, 162, 182, 0.3);
  border-color: transparent transparent rgba(20, 162, 182, 0.3) transparent;
  border-radius: 0 0 100% 100%;
}
.homepage_slider-members .swiper-scrollbar-drag {
  width: 66px !important;
  height: 66px !important;
  background-color: #14a2b6;
  border-radius: 33px;
  margin-top: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage_slider-members .swiper-scrollbar-drag:before {
  content: "";
  display: block;
  width: 9px;
  height: 18px;
  background: url("assets/icons/arrow-left-white.svg") no-repeat center center;
  margin-right: 15px;
}
.homepage_slider-members .swiper-scrollbar-drag:after {
  content: "";
  display: block;
  width: 9px;
  height: 18px;
  background: url("assets/icons/arrow-left-white.svg") no-repeat center center;
  transform: rotate(180deg);
}
.homepage_slider-members .swiper-scrollbar-drag:hover {
  cursor: pointer;
}
.homepage_slider-members .card-membre_item {
  width: 100%;
  transition: all 0.8s linear;
  margin: 0;
}
.homepage_slider-members .card-membre_item .card-membre_photo {
  width: auto;
  height: 226px;
}

.membre-container {
  display: flex;
}

.list-card-membres {
  display: flex;
  align-items: center;
  margin: 60px auto;
  flex-wrap: wrap;
  box-sizing: content-box;
}
.list-card-membres_no-result {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
  line-height: 25px;
  font-weight: bold;
}
@media (max-width: 639px) {
  .list-card-membres {
    justify-content: center;
  }
  .list-card-membres .card-membre_item {
    height: 172px;
    width: 171px;
    max-width: calc(50% - 16px);
    margin: 8px;
  }
  .list-card-membres .card-membre_photo {
    height: 172px;
    width: 171px;
    max-width: 100%;
  }
}

.membre_intro-container {
  max-width: 730px;
}
.membre_intro-container .block-page-title_h1 {
  text-align: left;
}
.membre_intro-container .block-page-title_baseline {
  text-align: left;
}

.membre_content {
  position: relative;
  float: right;
}

.page-template-template-rejoignez-nous-php .article-content {
  position: relative;
}
.page-template-template-rejoignez-nous-php .form_container {
  position: absolute;
  top: 50px;
  right: 50px;
}
@media (max-width: 899px) {
  .page-template-template-rejoignez-nous-php .form_container {
    position: static;
    top: 0;
    right: auto;
  }
}
.page-template-template-rejoignez-nous-php .footer {
  padding-top: 0;
  margin-top: 0;
}
.page-template-template-rejoignez-nous-php .footer_contact-container {
  display: none;
}

.rejoignez-nous_contact-informations {
  margin-top: 140px;
}
@media (max-width: 899px) {
  .rejoignez-nous_contact-informations {
    margin-top: 80px;
  }
}
.rejoignez-nous_contact_map {
  margin: 0 -50px;
}
@media (max-width: 899px) {
  .rejoignez-nous_contact_map {
    margin: 0 -25px;
  }
}
.rejoignez-nous_contact_map #map {
  width: 100%;
  height: 550px;
}

.notre-equipe {
  max-width: 1266px;
  margin: 0 auto;
}
@media (max-width: 899px) {
  .notre-equipe .article-feature {
    margin: 0 -25px;
  }
}
.notre-equipe .article-body {
  background-color: #fff;
  padding: 90px 100px 130px;
}
@media (max-width: 899px) {
  .notre-equipe .article-body {
    padding: 60px 20px 100px;
    margin: 0 -25px;
  }
}
.notre-equipe .article-body h2 {
  font-family: Chapman, sans-serif;
  font-size: 28px;
  color: #000;
  letter-spacing: 0.1px;
  line-height: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.notre-equipe .article-body p {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  line-height: 30px;
  letter-spacing: 0.1px;
}

.espace_zone-list {
  max-width: 1266px;
  margin: 270px auto 210px;
}
@media (max-width: 639px) {
  .espace_zone-list {
    margin: 100px auto 80px;
  }
}
.espace_zone-list_container {
  display: flex;
}
@media (max-width: 639px) {
  .espace_zone-list_container {
    flex-direction: column;
    align-items: center;
  }
}
.espace_city-advantages {
  max-width: 1266px;
  margin: 70px auto 60px;
}
@media (max-width: 639px) {
  .espace_city-advantages {
    margin: 50px auto;
  }
}
.espace_city-advantages_container {
  display: flex;
}
@media (max-width: 639px) {
  .espace_city-advantages_container {
    flex-direction: column;
    align-items: center;
  }
}

.container-pricing {
  transition: all 0.6s ease-in;
  transform: translateY(100%);
  opacity: 0;
}
.container-pricing.visible {
  transform: none;
  opacity: 1;
}

.pricing-information {
  margin-bottom: 90px;
}
.pricing-information p {
  margin-bottom: 4px;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #000;
  text-align: center;
  line-height: 25px;
}
.pricing-information p:last-child {
  margin-bottom: 0;
}

.popin {
  display: none;
}
.popin.opened {
  display: block;
}
.popin_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
@media (max-width: 899px) {
  .popin_container {
    align-items: start;
  }
}
.popin_content {
  width: 633px;
  height: 750px;
  padding: 90px 55px;
  max-width: 100%;
  background: url("assets/pictures/background-s-blue.svg") no-repeat center center #1584c3;
  overflow: scroll;
  position: relative;
  background-size: cover;
}
@media (max-width: 899px) {
  .popin_content {
    width: 100%;
    height: 100%;
    padding: 25px;
  }
}
.popin_close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}
.popin_close-btn:hover {
  cursor: pointer;
}
.popin_form {
  padding: 0 5px 50px;
}
.popin_form label {
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.3px;
  line-height: 27px;
}
.popin_form input[type=text], .popin_form input[type=email], .popin_form textarea, .popin_form label {
  width: 100%;
}
.popin_form input[type=text], .popin_form input[type=email], .popin_form textarea {
  border: none;
  background-color: #fff;
  border-radius: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 15px;
  color: #959595;
  letter-spacing: 3px;
  line-height: 18px;
  padding: 16px 10px;
  margin-top: 10px;
  margin-bottom: 0;
  height: auto;
}
.popin_form textarea, .popin_form textarea[row] {
  height: 150px !important;
  line-height: 27px;
}
.popin_form input[type=submit] {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  color: #fff;
  text-align: center;
  letter-spacing: 3px;
  line-height: 26px;
  height: 50px;
  width: 181px;
  background-color: #faae48;
  border: none;
}
.popin_form .wpcf7-form.sent {
  display: none;
}
.popin_form .screen-reader-response {
  height: 100% !important;
  position: relative !important;
  width: 100% !important;
  clip-path: none !important;
}
.popin_form .screen-reader-response p {
  font-family: "Work Sans", sans-serif;
  font-size: 20px;
  color: #fff;
  line-height: 40px;
  text-align: center;
}
.popin_title {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0.3px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
}
.popin_label {
  margin-top: 40px;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  letter-spacing: 0.3px;
}
.popin_button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popin_button {
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.3px;
  line-height: 20px;
  background-color: #faae48;
  padding: 20px 50px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  margin-top: 40px;
}
.popin_button:hover {
  cursor: pointer;
  color: #faae48;
  background-color: #fff;
}